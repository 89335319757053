import { defineComponent, type PropType } from 'vue'

import { autoInitMdc } from '@/javascript/vuejs/helpers/autoInitMDC'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	'input': (value?: boolean): boolean => true,
	'showModal': (): boolean => true,
}
/* eslint-enable */

export default defineComponent({
	name: 'InputCheckbox',

	props: {
		...on(emits),

		additionalClasses: { default: '', type: String },
		additionalInputClasses: { default: '', type: String },
		dataTest: { default: '', type: String },
		disabled: { default: false, type: Boolean },
		id: { default: '', type: String },
		isErrored: { default: false, type: Boolean },
		labelText: { default: '', type: String },
		modalLink: { default: '', type: String },
		modalName: { default: '', type: String },
		required: { default: false, type: Boolean },
		tooltipLocation: { optional: true, type: String as PropType<TooltipLocation> },
		tooltipText: { default: '', type: String },
		value: { optional: true, type: Boolean },
	},

	emits,

	mounted() {
		autoInitMdc(this.$el)
	},

	methods: {
		handleInput(): void {
			this.$emit('input', !this.value)
		},
	},

	render() {
		return (
			<div
				class={[
					'input-line input-line--full-width mdc-form-field input-checkbox',
					this.additionalClasses,
				]}
			>
				<div
					class="mdc-checkbox"
					v-tooltip={this.tooltipLocation === 'input' ? this.tooltipText : ''}
				>
					<input
						aria-labelledby={this.labelText}
						checked={this.value}
						class={['mdc-checkbox__native-control', this.additionalInputClasses]}
						data-test={this.dataTest}
						disabled={this.disabled}
						id={this.id}
						name={this.id}
						type="checkbox"
						onInput={this.handleInput}
					/>

					<div class="mdc-checkbox__background">
						<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
							<path
								class="mdc-checkbox__checkmark-path"
								fill="none"
								d="M1.73,12.91 8.1,19.28 22.79,4.59"
							/>
						</svg>

						<div class="mdc-checkbox__mixedmark" />
					</div>
				</div>

				<label
					class="pointer hover-mid-gray"
					for={this.id}
				>
					{this.required && <em>*</em>}	{this.labelText}&nbsp;
				</label>

				{!!this.modalName &&
					<a
						class="text-link"
						data-test="show-modal-text-link"
						href="javascript:void(0)"
						onClick={(): string => GlobalStore.emittedEvent = this.modalName}
					>
						{this.modalLink}
					</a>
				}

				{this.tooltipLocation === 'label' &&
					<i
						class="info-notice__icon info-notice__icon--info fa"
						v-tooltip={this.tooltipText}
					/>
				}

				<div class="mdc-line-ripple" />
			</div>
		)
	},
})

type TooltipLocation = 'input' | 'label'
